
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);
    const email = ref<string>("");

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string()
        .email()
        .required()
        .label("Email")
    });

    //Form submit function
    const onSubmitForgotPassword = values => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store
          .dispatch(Actions.FORGOT_PASSWORD, {
            email: email.value
          })
          .then(() => {
            Swal.fire({
              text: "Please check your email for a link to reset your password.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });

            submitButton.value?.removeAttribute("data-kt-indicator");
          })
          .catch(() => {
            // Alert then login failed
            Swal.fire({
              text: "User with this email does not exist.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });

            submitButton.value?.removeAttribute("data-kt-indicator");
          });


    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      email,
      submitButton
    };
  }
});
